<template>
  <main class="member-shop-product-create">
    <div class="card-container form-wrapper">
      <SectionTitle :title="pageTitle" hideTitleStick hideBtn />

      <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
        <BaseElFormItem label="商品性質" prop="type" data-permit="admin.classTicket.page">
          <BaseElRadioGroup v-model="formData.type" :disabled="!!productId" @change="typeChangeHandler">
            <BaseElRadio
              v-for="type in displayProductTypeOptions"
              :key="type.value"
              :label="type.value"
            >
              {{ type.label }}
            </BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>
        <BaseElFormItem label="圖片" prop="image" class="form-relative-label others">
          <UploadButton
            cyUploadBtn="service-img-upload-btn"
            cyUploadedImg="service-img-uploaded"
            :img="formData.image"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
        <BaseElFormItem v-if="['classTicket', 'wallet'].includes(formData.type)" label="是否永豐價保" prop="performance">
          <BaseElRadioGroup v-model="formData.performance" :disabled="!!productId" @change="formData.classTicket = null">
            <BaseElRadio v-if="formData.type !== 'wallet'" :label="true">是</BaseElRadio>
            <BaseElRadio :label="false">否</BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>
        <BaseElFormItem v-if="formData.performance && formData.type === 'classTicket'" label="支付方式" prop="promisePaymentConfigId">
          <BaseElSelect v-model="formData.promisePaymentConfigId" :disabled="!!productId && formData.type === 'classTicket'" @change="performanceChange">
            <BaseElSelectOption
              v-for="type in displayPaymentTypeOptions"
              :key="type.id"
              :label="type.name"
              :value="type.id"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <!-- 堂票綁定 -->
        <BaseElFormItem v-if="formData.type === 'classTicket'" label="堂票綁定" prop="classTicket">
          <BaseElSelect
            v-if="features.useClassTicket"
            v-model="formData.classTicket"
            popper-class="select-popper"
            placeholder="選擇堂票"
            :disabled="!!productId && formData.type === 'classTicket'"
            @visible-change="selector.classTicket = true"
          >
            <BaseElSelectOption
              :label="findCouponName(formData.classTicket, 'classTicket')"
              :value="formData.classTicket"
            />
          </BaseElSelect>
          <p v-else class="text-danger">未開啟堂票模組故無法顯示</p>
        </BaseElFormItem>

        <!-- 點數卡綁定 -->
        <BaseElFormItem v-if="formData.type === 'pointCard'" label="點數卡綁定" prop="pointCard">
          <BaseElSelect
            v-if="features.usePointCard"
            v-model="formData.pointCard"
            popper-class="select-popper"
            placeholder="選擇點數卡"
            :disabled="!!productId && formData.type === 'pointCard'"
            @visible-change="selector.pointCard = true"
          >
            <BaseElSelectOption
              :label="findCouponName(formData.pointCard, 'pointCard')"
              :value="formData.pointCard"
            />
          </BaseElSelect>
          <p v-else class="text-danger">未開啟堂票模組故無法顯示</p>
        </BaseElFormItem>
        <!-- 次卡綁定 -->
        <BaseElFormItem v-if="formData.type === 'punchCard'" label="次卡綁定" prop="punchCard">
          <BaseElSelect
            v-if="features.usePunchCard"
            v-model="formData.punchCard"
            popper-class="select-popper"
            placeholder="選擇次卡"
            :disabled="!!productId && formData.type === 'punchCard'"
            @visible-change="selector.punchCard = true"
          >
            <BaseElSelectOption
              :label="findCouponName(formData.punchCard, 'punchCard')"
              :value="formData.punchCard"
            />
          </BaseElSelect>
          <p v-else class="text-danger">未開啟堂票模組故無法顯示</p>
        </BaseElFormItem>

        <div v-if="formData.type === 'periodicBenefit'" class="mb-[20px]">
          <BaseElFormItem label="會員權益範本綁定" prop="periodicBenefitTemplate">
            <BaseElSelect v-model="formData.periodicBenefitTemplate" placeholder="請選擇" clearable @change="updateDescription">
              <BaseElSelectOption v-for="item in periodicBenefitTemplateList" :key="item.id" :label="item.name" :value="item.id" />
            </BaseElSelect>
          </BaseElFormItem>
          <GrayBlockContainer v-show="formData.periodicBenefitTemplate" style="width: 560px">
            <div class="flex flex-col gap-[8px]">
              <div v-for="(item, idx) in displaySelectPeriodicBenefitTemplateData" :key="idx" class="flex text-gray-100 text-sub gap-[20px]">
                <span class="font-medium flex-shrink-0" style="width: 75px">{{ item.label }}</span>
                <div>
                  <span class="font-norma whitespace-pre-line">{{ item.value }}</span>
                </div>
              </div>
            </div>
          </GrayBlockContainer>
        </div>

        <BaseElFormItem label="名稱" prop="name">
          <BaseElInput v-model="formData.name" placeholder="商品名稱" />
        </BaseElFormItem>
        <BaseElFormItem label="公開顯示" prop="isPublic">
          <BaseElSwitch
            v-model="formData.isPublic"
            inactive-text="關閉"
            active-text="開啟"
            @change="checkProductExp"
          />
        </BaseElFormItem>
        <BaseElFormItem label="預設售價" prop="price">
          <BaseElInput v-model="formData.price" placeholder="請輸入欲販售金額" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.type === 'wallet'"
          label="儲值金金額"
          prop="walletPrice"
          :disabled="!!productId && formData.type === 'wallet'"
        >
          <BaseElInput v-model="formData.walletPrice" placeholder="請輸入購買後實際儲值的金額" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.type === 'externalWallet'"
          :label="`${externalWalletName}金額`"
          prop="externalWalletAmount"
          :disabled="!!productId && formData.type === 'externalWallet'"
        >
          <BaseElInput v-model="formData.externalWalletAmount" placeholder="請輸入購買後實際儲值的金額" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="checkAction('adminView.ecommerceProduct.useCashbackRate')"
          label="回饋金可折抵上限（%）"
          prop="maxDiscount"
          data-permit="adminView.ecommerceProduct.useCashbackRate"
        >
          <BaseElInput v-model="formData.maxDiscount" placeholder="請輸入此商品可被折抵的上限" />
        </BaseElFormItem>
        <!-- <BaseElFormItem label="商品庫存" prop="stock">
          <div class="flex flex-col">
            <BaseElInput v-model="formData.stock" placeholder="請輸入庫存數量限制" :disabled="formData.stockUnlimit" />
            <BaseElCheckbox v-model="formData.stockUnLimit">無限制庫存</BaseElCheckbox>
          </div>
        </BaseElFormItem> -->
        <BaseElFormItem label="商品描述" prop="description">
          <template v-if="formData.type === 'periodicBenefit'" #label>
            <div class="flex items-center" style="gap: 8px">
              <p>商品描述</p>
              <TipInfo :size="16" width="200">
                自動帶入會員權益範本的商品描述
              </TipInfo>
            </div>
          </template>
          <quillEditor v-model="formData.description" :options="editorOption" :disabled="formData.type === 'periodicBenefit'" />
        </BaseElFormItem>
        <BaseElFormItem label="注意事項" prop="notice">
          <quillEditor v-model="formData.notice" :options="editorOption" />
        </BaseElFormItem>
        <BaseElFormItem label="排序" prop="order">
          <BaseElInput v-model="formData.order" placeholder="請輸入排序" />
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <PageFixedFooter :confirmLoading="submitting" @cancel="onCancel" @confirm="onConfirm" />
    <ImageCropper v-if="modal.imageCropper" :image="formData.image" @uploaded="getImage" @close="modal.imageCropper = false" />

    <!-- 堂票選擇器 -->
    <ClassTicketAdvanceSelect
      v-if="selector.classTicket"
      :data="classTicketDisplayFilter"
      :typeOptions="couponTypeConfig"
      :selectedData="formData.classTicket ? [formData.classTicket] : []"
      :disabledExp="true"
      @confirm="onSelectResource($event, 'classTicket')"
      @close="selector.classTicket = false"
    />

    <!-- 點數卡選擇器 -->
    <BaseAdvanceSelect
      v-if="selector.pointCard"
      title="選擇點數卡"
      :data="resourceData.pointCard"
      @close="selector.pointCard = false"
      @confirm="onSelectResource($event, 'pointCard')"
    />
    <!-- 次卡選擇器 -->
    <BaseAdvanceSelect
      v-if="selector.punchCard"
      title="選擇次卡"
      :data="resourceData.punchCard"
      @close="selector.punchCard = false"
      @confirm="onSelectResource($event, 'punchCard')"
    />

    <WarningDialog
      v-if="modal.expWarn"
      title="提醒：此商品已經過期"
      hideCancel
      btnString="關閉"
      @close="modal.expWarn = false"
      @confirm="modal.expWarn = false"
    >
      <template slot="body">
        <div>
          無法將已經過期的商品公開顯示於會員商城。
        </div>
      </template>
    </WarningDialog>
  </main>
</template>

<script>
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { useRoute, useRouter } from 'vue-router/composables'
import { usePermissions } from '@/use/permissions'
import { noEmptyRules, rangeRules, isDigitRules } from '@/validation'
import formUtils from '@/utils/form'
import { ellipsisText, passValue } from '@/utils/helper'
import store from '@/store'
import { FindMemberShopProduct, CreateMemberShopProduct, UpdateMemberShopProduct, GetMemberShopPaymentConfig } from '@/api/memberShop'
import { memberShopProductTypeConfig, orderPaymentTypeConfig } from '@/config/memberShop'
import { couponTypeConfig } from '@/config/couponExchange'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { get, omit, filter, isNull, find, join, map } from 'lodash'
import dayjs from '@/lib/dayjs'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import ClassTicketAdvanceSelect from '@/components/Select/ClassTicketAdvanceSelect.vue'
import { GetClassTicket } from '@/api/classTicket'
import { useFetch } from '@/use/fetch'
import { GetPeriodicBenefitTemplate } from '@/api/periodicBenefit'
import { usePeriodBenefitTemplate } from '@/use/usePeriodBenefit'
import { useExternalWallet } from '@/use/useExternalWallet'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { GetPointCard } from '@/api/pointCard'
import { GetPunchCard } from '@/api/punchCard'
import TipInfo from '@/components/TipInfo.vue'
import BaseAdvanceSelect from '@/components/Select/BaseAdvanceSelect .vue'

export default defineComponent({
  name: 'MemberShopProductEdit',
  components: {
    SectionTitle,
    PageFixedFooter,
    quillEditor,
    UploadButton,
    ImageCropper,
    WarningDialog,
    ClassTicketAdvanceSelect,
    GrayBlockContainer,
    BaseAdvanceSelect,
    TipInfo,
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const { fetchAll } = useFetch()
    const { checkAction } = usePermissions()
    const { externalWalletName, getExternalWalletConfig } = useExternalWallet()
    const { getFormatEntitlementList, formatPeriodPreviewText } = usePeriodBenefitTemplate()
    const resourceData = reactive({
      classTicket: [],
      periodicBenefitTemplate: [],
      pointCard: [],
      punchCard: [],
      coupon: [],
    })
    const editorOption = {
      modules: {
        toolbar: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    }
    const displayPaymentTypeOptions = computed(() => {
      return filter(shopPaymentConfig.value, item => item.type === 'sinoPacEpos' && item.limitProductType === 'classTicket' && item.isPaymentPromise && item.enable)
    })
    const displayProductTypeOptions = computed(() => {
      const omitList = ['cashback']
      if (!features.useClassTicket)omitList.push('classTicket')
      if (!features.usePeriodicBenefitTemplate)omitList.push('periodicBenefit')
      if (!features.useExternalWallet)omitList.push('externalWallet')
      if (!features.useWallet)omitList.push('wallet')
      if (!features.usePointCard)omitList.push('pointCard')
      if (!features.usePunchCard)omitList.push('punchCard')

      return map(omit(memberShopProductTypeConfig, omitList), (item) => {
        return {
          label: item.value === 'externalWallet' ? externalWalletName.value : item.label,
          value: item.value,
        }
      })
    })
    const shopId = computed(() => store.getters.shop)
    const pageTitle = computed(() => {
      if (!productId.value) return '新增商城商品'
      return '編輯商城商品'
    })

    const periodicBenefitTemplateList = ref([])
    const features = reactive({
      useChashback: computed(() => checkAction('admin.shopCashback.page')),
      useClassTicket: computed(() => checkAction('admin.classTicket.page')),
      useCouponExchange: computed(() => checkAction('admin.couponExchange.page')),
      useCoupon: computed(() => checkAction('admin.coupon.page')),
      usePeriodicBenefitTemplate: computed(() => checkAction('admin.periodicBenefitTemplate.page')),
      useExternalWallet: computed(() => checkAction('admin.externalWallet.page')),
      useWallet: computed(() => checkAction('admin.walletRecord.page')),
      usePointCard: computed(() => checkAction('admin.memberStoreProduct.allowPointCardType')),
      usePunchCard: computed(() => checkAction('admin.memberStoreProduct.allowPunchCardType')),
    })
    const productId = computed(() => {
      return route.params.id
    })
    const productData = ref(null)
    const compactPostData = computed(() => {
      const { type, image, name, notice, isPublic, price, description, walletPrice, externalWalletAmount, classTicket, pointCard, punchCard, order, maxDiscount, promisePaymentConfigId } = formData
      const performance = formData.performance
      return {
        type,
        walletAmount: walletPrice,
        externalWalletAmount,
        classTicketId: type === 'classTicket' ? classTicket : undefined,
        pointCardId: type === 'pointCard' ? pointCard : undefined,
        punchCardId: type === 'punchCard' ? punchCard : undefined,
        periodicBenefitTemplateId: formData.periodicBenefitTemplate || undefined,
        name,
        description,
        notice,
        imageId: get(image, 'id') || null,
        isPublic,
        isPaymentPromise: performance,
        price,
        useCashbackRate: maxDiscount / 100,
        order,
        promisePaymentConfigId,
      }
    })
    const formRef = ref(null)
    const submitting = ref(false)
    const formData = reactive({
      type: 'classTicket',
      image: null,
      performance: false,
      classTicket: null,
      punchCard: null,
      pointCard: null,
      periodicBenefitTemplate: null,
      name: null,
      isPublic: true,
      price: null,
      walletPrice: null,
      externalWalletAmount: null,
      maxDiscount: null,
      stock: null,
      description: null,
      notice: null,
      order: 100,
      promisePaymentConfigId: null,
    })
    const shopPaymentConfig = ref([])
    const formRules = {
      type: [noEmptyRules()],
      performance: [noEmptyRules()],
      classTicket: [noEmptyRules()],
      name: [noEmptyRules()],
      isPublic: [noEmptyRules()],
      price: [noEmptyRules(), isDigitRules()],
      walletPrice: [noEmptyRules(), isDigitRules(), rangeRules(1, 50000, '單次儲值金額不可超過新台幣 50,000')],
      externalWalletAmount: [noEmptyRules(), isDigitRules(), rangeRules(1, 50000, '單次儲值金額不可超過新台幣 50,000')],
      maxDiscount: [noEmptyRules(), isDigitRules(), rangeRules(0, 100)],
      stock: [noEmptyRules(), isDigitRules()],
      description: [noEmptyRules()],
      notice: [noEmptyRules()],
      order: [noEmptyRules(), isDigitRules()],
      promisePaymentConfigId: [noEmptyRules()],
      periodicBenefitTemplate: [noEmptyRules()],
      pointCard: [noEmptyRules()],
      punchCard: [noEmptyRules()],
    }

    const displaySelectPeriodicBenefitTemplateData = computed(() => {
      if (!formData.periodicBenefitTemplate) return []
      const periodicBenefitTemplate = find(periodicBenefitTemplateList.value, { id: formData.periodicBenefitTemplate })

      const periodConfig = get(periodicBenefitTemplate, 'period', {})
      const startType = periodConfig.startType
      const periodType = periodConfig.type
      const instantAward = get(periodicBenefitTemplate, 'instantAward')
      let fixedWeekDay = '-'
      const nDayValue = periodConfig.nDayValue || '-'
      const fixedValue = periodConfig.fixedValue || '-'
      const totalAwardCount = get(periodicBenefitTemplate, 'totalAwardCount', '-')
      if (periodType === 'weekly' && startType === 'fixed') {
        fixedWeekDay = fixedValue
      }

      const data = [
        {
          label: '權益項目',
          value: join(getFormatEntitlementList(periodicBenefitTemplate), '\n'),
        },
        {
          label: '發放週期',
          value: formatPeriodPreviewText({
            startType,
            periodType,
            fixedWeekDay,
            nDayValue,
            fixedValue,
            instantAward,
            totalAwardCount,
          }, { highLight: false }),
        },
        {
          label: '發放總次數',
          value: get(periodicBenefitTemplate, 'totalAwardCount', '-'),
        },
      ]

      return data
    })
    const modal = reactive({
      imageCropper: false,
      expWarn: false,
    })

    const selector = reactive({
      classTicket: false,
      pointCard: false,
      punchCard: false,
    })

    const validateForm = async () => {
      return await formUtils.checkForm(formRef.value)
    }

    const findProduct = async () => {
      const [res, err] = await FindMemberShopProduct({
        shopId: shopId.value,
        id: productId.value,
      })
      if (err) return window.$message.error(err)
      productData.value = res
    }

    const createProduct = async () => {
      const [, err] = await CreateMemberShopProduct({
        shopId: shopId.value,
        ...compactPostData.value,
      })
      if (err) return window.$message.error(err)
      window.$message.success('新增商品成功！')
      router.push({
        name: 'MemberShopProductSettings',
      })
    }
    const updateProduct = async () => {
      const [, err] = await UpdateMemberShopProduct({
        shopId: shopId.value,
        id: productId.value,
        ...compactPostData.value,
      })
      if (err) return window.$message.error(err)
      window.$message.success('更新商品成功！')
      router.push({
        name: 'MemberShopProductSettings',
      })
    }
    const updateDescription = (selectedValue) => {
      const selectedTemplate = find(periodicBenefitTemplateList.value, item => item.id === selectedValue)
      if (selectedTemplate) {
        formData.description = selectedTemplate.description
      }
    }
    const syncData = (res) => {
      const data = productData.value
      formData.type = data.type
      formData.promisePaymentConfigId = data.PromisePaymentConfigId
      formData.name = data.name
      formData.periodicBenefitTemplate = data.PeriodicBenefitTemplateId
      formData.image = data.Image
      formData.isPublic = data.isPublic
      formData.notice = data.notice
      formData.description = data.description
      formData.walletPrice = data.walletAmount
      formData.externalWalletAmount = data.externalWalletAmount
      formData.performance = data.isPaymentPromise
      formData.price = data.price
      formData.order = data.order
      formData.classTicket = data.ClassTicketId
      formData.pointCard = data.PointCardId
      formData.punchCard = data.PunchCardId
      formData.maxDiscount = data.useCashbackRate * 100
    }

    const onCancel = () => {
      router.push({ name: 'MemberShopProductSettings' })
    }

    const onConfirm = async () => {
      submitting.value = true
      if (!await validateForm()) {
        submitting.value = false
        return
      }

      if (!productId.value) {
        await createProduct()
      } else {
        await updateProduct()
      }
      submitting.value = false
    }

    const getImage = (data) => {
      formData.image = data
      modal.imageCropper = false
    }
    const loadImg = (img) => {
      formData.image = img
      modal.imageCropper = true
    }

    const classTicketDisplayLabel = (item, index) => {
      return `${index + 1}. ${ellipsisText(item.name, 35)}`
    }
    const classTicketDisplayFilter = computed(() => {
      if (!formData.performance) {
        return resourceData.classTicket
      }
      if (!isNull(formData.promisePaymentConfigId)) {
        // const paymentData = find(shopPaymentConfig.value, { id: formData.promisePaymentConfigId })
        // const promisePeriodDays = get(paymentData, 'PaymentPromiseConfig.promisePeriodDays')
        // return classTicketList.value.filter(i => i.isValuable && !isNull(i.expDay) && i.expDay === promisePeriodDays)
        return filter(resourceData.classTicket, i => i.isValuable && !i.isExp)
      }
      return []
    })
    const performanceChange = () => {
      formData.classTicket = null
    }
    const onClassTicketChange = (data) => {
      if (!formData.image) formData.image = data.Image
    }

    const typeChangeHandler = () => {
      if (formData.type === 'wallet') {
        formData.performance = false
      } else if (formData.type === 'periodicBenefit') {
        formData.performance = false
      }

      formData.classTicket = null
      formData.pointCard = null
      formData.punchCard = null
    }

    const checkProductExp = (isPublic) => {
      if (!isPublic) return
      const productType = get(productData.value, 'type')
      let product
      if (productType === 'classTicket') product = get(productData.value, 'ClassTicket')
      else if (productType === 'coupon') product = get(productData.value, 'Coupon')

      const expDate = get(product, 'specifyExpiredDate') || get(product, 'expAt')
      console.log(productType, expDate, product)
      if (!expDate) return

      if (dayjs(expDate).isBefore(dayjs())) {
        modal.expWarn = true
        formData.isPublic = false
      }
    }
    const getMemberShopPaymentConfig = async () => {
      const [res, err] = await GetMemberShopPaymentConfig({
        shopId: shopId.value,
        start: 0,
      })
      if (err) window.$message.error(err)
      shopPaymentConfig.value = res
    }

    const onSelectResource = (data, type) => {
      if (!data.length) return
      if (type === 'classTicket') {
        formData.classTicket = data[0]
      } else if (type === 'pointCard') {
        formData.pointCard = data[0]
      } else if (type === 'punchCard') {
        formData.punchCard = data[0]
      }
    }

    const getResourceData = async () => {
      const callList = []
      if (features.useClassTicket) {
        callList.push(async () => {
          await fetchAll(GetClassTicket, { shopId: shopId.value }, (res) => {
            resourceData.classTicket = res
          })
        })
      }
      if (features.usePeriodicBenefitTemplate) {
        callList.push(async () => {
          await fetchAll(GetPeriodicBenefitTemplate, { shopId: shopId.value }, (res) => {
            // resourceData.periodicBenefitTemplate = res
            periodicBenefitTemplateList.value = filter(res, { type: 'standard' })
          })
        })
      }
      if (features.usePointCard) {
        callList.push(async () => {
          await fetchAll(GetPointCard, { shopId: shopId.value }, (res) => {
            resourceData.pointCard = res
          })
        })
      }
      if (features.usePunchCard) {
        callList.push(async () => {
          await fetchAll(GetPunchCard, { shopId: shopId.value }, (res) => {
            resourceData.punchCard = res
          })
        })
      }

      await Promise.all(callList.map((call) => call()))
    }

    const findCouponName = (idx, type) => {
      let dataList
      if (type === 'coupon') dataList = passValue(resourceData.coupon)
      else if (type === 'classTicket') dataList = passValue(resourceData.classTicket)
      else if (type === 'pointCard') dataList = passValue(resourceData.pointCard)
      else if (type === 'punchCard') dataList = passValue(resourceData.punchCard)
      const coupon = dataList.find(({ id }) => id === idx)
      if (coupon) return coupon.name
      return ''
    }

    onMounted(async () => {
      await Promise.all([
        getMemberShopPaymentConfig(),
        getResourceData(),
      ])

      if (productId.value) {
        await findProduct()
        syncData()
      }
      await getExternalWalletConfig()
    })

    return {
      onClassTicketChange,
      checkAction,
      classTicketDisplayLabel,
      classTicketDisplayFilter,
      editorOption,
      formData,
      formRules,
      submitting,
      onCancel,
      onConfirm,
      productId,
      formRef,
      pageTitle,
      memberShopProductTypeConfig,
      modal,
      getImage,
      loadImg,
      // showProductTypeOptions,
      productData,
      displayProductTypeOptions,
      typeChangeHandler,
      checkProductExp,
      displayPaymentTypeOptions,
      shopPaymentConfig,
      findCouponName,
      couponTypeConfig,
      onSelectResource,
      features,
      performanceChange,
      periodicBenefitTemplateList,
      displaySelectPeriodicBenefitTemplateData,
      externalWalletName,
      selector,
      resourceData,
      updateDescription,
    }
  },
})
</script>

<style scoped lang="postcss">
.form-wrapper {
  @apply px-[32px] py-[24px];
}

.form-relative-label {
  @apply relative;
}

.form-relative-label.others::after {
  content: '(建議上傳尺寸1200x1200)';
  @apply absolute left-[50px] top-[2px] text-sm text-gray-80;
}
</style>
